<template>
  <div>
    授权
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "AuthPage",
  mounted() {
    const {authorization_code} = this.$route.query
    this.fetchToken(authorization_code)
  },
  methods: {
    fetchToken(authorization_code) {
      request({
        url: '/api/admin/token',
        query: {
          authorization_code
        }
      }).then(res => {
        console.log(res)
        const {master_id, refresh_token, token} = res
        localStorage.setItem('refresh_token',refresh_token)
        localStorage.setItem('token',token)
        this.$router.push('/')
      })
    }

  }
}
</script>

<style scoped>

</style>
